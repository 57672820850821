import React from 'react'
import { Waypoint } from 'react-waypoint'

const WatchElementAnimate = ({ children, className, animateName }) => {
  const handleAnimate = () => {
    const element = document.querySelector(className)
    element.classList.add('animate__animated', animateName)
  }
  return <Waypoint onEnter={handleAnimate}>{children}</Waypoint>
}
export default WatchElementAnimate
